// TODO: This temporary, refactor or use data from db!
export type League = {
  id: number;
  title: string;
  icon: string;
  theme: string;
};
export const getLeagues = () => [
  {
    id: 155,
    title: "Kontraktslösa",
    icon: "13",
    theme: "#000000",
    countryName: "Kontraktslosa",
  },
  {
    id: 11,
    title: "Utlandsproffs",
    icon: "11",
    theme: "#FFCE00",
    countryName: "Sverige",
  },
  {
    id: 3,
    title: "Allsvenskan",
    icon: "3",
    theme: "#00045E",
    countryName: "Sverige",
  },
  {
    id: 4,
    title: "Superettan",
    icon: "4",
    theme: "#006A2C",
    countryName: "Sverige",
  },
  {
    id: 6,
    title: "Ettan Norra",
    icon: "6",
    theme: "#00294E",
    countryName: "Sverige",
  },
  {
    id: 7,
    title: "Ettan Södra",
    icon: "7",
    theme: "#002A4D",
    countryName: "Sverige",
  },
  {
    id: 8,
    title: "Premier League",
    icon: "8",
    theme: "#370040",
    countryName: "England",
  },
  {
    id: 9,
    title: "Serie A",
    icon: "9",
    theme: "#0f3059",
    countryName: "Italien",
  },
  {
    id: 30,
    title: "Bundesliga",
    icon: "30",
    theme: "#DB0000",
    countryName: "Tyskland",
  },
  {
    id: 10,
    title: "La Liga",
    icon: "10",
    theme: "#FF4B44",
    countryName: "Spanien",
  },
  {
    id: 33,
    title: "Ligue 1",
    icon: "33",
    theme: "#252525",
    countryName: "Frankrike",
  },
];

export const getLeagueMenu = (alternativeOrder?: boolean) => {
  let leagueMenu = [
    { key: "allsvenskan", title: "Allsvenskan", leagueId: 3 },
    { key: "premier-league", title: "Premier League", leagueId: 8 },
    { key: "serie-a", title: "Serie A", leagueId: 9 },
    { key: "la-liga", title: "La Liga", leagueId: 10 },
    { key: "superettan", title: "Superettan", leagueId: 4 },
    { key: "utlandsproffs", title: "Utlandsproffs", leagueId: 11 },
    { key: "ettan-norra", title: "Ettan Norra", leagueId: 6 },
    { key: "ettan-sodra", title: "Ettan Södra", leagueId: 7 },
    { key: "bundesliga", title: "Bundesliga", leagueId: 30 },
    { key: "ligue-1", title: "Ligue 1", leagueId: 33 },
  ];

  if (alternativeOrder) {
    return [
      { key: "senaste", title: "Senaste", leagueId: 0 },
      { key: "start", title: "Toppnyheter", leagueId: 0 },
      ...leagueMenu,
    ];
  }

  return [
    { key: "start", title: "Toppnyheter", leagueId: 0 },
    { key: "senaste", title: "Senaste", leagueId: 0 },
    ...leagueMenu,
  ];
};

export const getLeagueLogoUrl = (categoryId: number) => {
  if (categoryId === 3)
    return `${process.env.NEXT_PUBLIC_ASSET_SERVER_URL}/uploads/images/leagues/1638206654/full.png`;
  if (categoryId === 4) return "/leagues/4.png";
  if (categoryId === 6)
    return `${process.env.NEXT_PUBLIC_ASSET_SERVER_URL}/uploads/images/leagues/1625167314/full.png`;
  if (categoryId === 7)
    return `${process.env.NEXT_PUBLIC_ASSET_SERVER_URL}/uploads/images/leagues/1625167314/full.png`;
  if (categoryId === 8)
    return `${process.env.NEXT_PUBLIC_ASSET_SERVER_URL}/uploads/images/leagues/1625168655/full.png`;
  if (categoryId === 9)
    return `${process.env.NEXT_PUBLIC_ASSET_SERVER_URL}/uploads/images/leagues/1625171197/full.png`;
  if (categoryId === 10)
    return `${process.env.NEXT_PUBLIC_ASSET_SERVER_URL}/uploads/images/leagues/1625170344/full.png`;
  if (categoryId === 11)
    return `${process.env.NEXT_PUBLIC_ASSET_SERVER_URL}/uploads/images/leagues/1625167958/full.png`;
  if (categoryId === 17)
    return `${process.env.NEXT_PUBLIC_ASSET_SERVER_URL}/uploads/images/leagues/1625167958/full.png`;
  if (categoryId === 12)
    return `${process.env.NEXT_PUBLIC_ASSET_SERVER_URL}/uploads/images/clubs/1614604808/full.png`;
  if (categoryId === 22) return "/leagues/11.png";
  if (categoryId === 14)
    return `${process.env.NEXT_PUBLIC_ASSET_SERVER_URL}/uploads/images/clubs/1614605582/full.png`;
  if (categoryId === 16)
    return `${process.env.NEXT_PUBLIC_ASSET_SERVER_URL}/uploads/images/clubs/1614535371/full.png`;
  if (categoryId === 15) return "/leagues/6.png";
  if (categoryId === 13) return "/leagues/4.png";
  if (categoryId === 18)
    return `${process.env.NEXT_PUBLIC_ASSET_SERVER_URL}/uploads/images/clubs/1624458970/full.png`;
  if (categoryId === 23)
    return `${process.env.NEXT_PUBLIC_ASSET_SERVER_URL}/images/flags/10a.png`;
  if (categoryId === 30) return "/leagues/30.png";
  if (categoryId === 33)
    return `${process.env.NEXT_PUBLIC_ASSET_SERVER_URL}/uploads/images/leagues/1625169720/full.png`;

  return null;
};
